/*----- 13. Testimonial style  ------*/

.single-testimonial {
  img {
    border-radius: 50%;
  }
  p {
    font-family: Montserrat-MediumItalic;

    line-height: 32px;
    color: $third-color;
    font-size: 22px;
    font-weight: 500;
    margin: 32px 0 0px;
    @media #{$xs-layout} {
      font-size: 15px;
    }
  }
  .client-info {
    margin: 20px 0 0;
    i {
      font-size: 26px;
      color: #666666;
    }
    h5 {
      font-size: 32px;
      color: $secundary-color;
      text-transform: uppercase;
      margin-top:3.5rem;
    }
    span {
      font-size: 14px;
      color: #010101;
      letter-spacing: 1.5px;
    }
  }
}

.testimonial-active {
  .swiper-button-next {
    right: 0 !important;
  }
  .swiper-button-prev {
    left: 0 !important;
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;
      opacity: 1;
    }
  }
}

.testimonial-area {
  .title-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h2{
      font-size: 40px;
      font-family: $barlowregular;
      color: $third-color;  
       @media #{$xs-layout} {
      font-size: 18px;
    }
    }
    #italic{
      font-size: 40px;
      font-family: Montserrat-BoldItalic;
      color: $theme-color;
      margin-left: 0.5rem;
       @media #{$xs-layout} {
      font-size: 18px;
    }
    }
  }
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    opacity: 1;
    visibility: visible;
  }
  &.ml-70 {
    @media #{$xl-layout} {
      margin-left: 15px;
    }
    @media #{$lg-layout} {
      margin-left: 30px;
    }
    @media #{$md-layout} {
      margin-left: 40px;
    }
    @media #{$xs-layout} {
      margin-left: 15px;
    }
    @media #{$sm-layout} {
      margin-left: 30px;
    }
  }
  &.mr-70 {
    @media #{$xl-layout} {
      margin-right: 15px;
    }
    @media #{$lg-layout} {
      margin-right: 30px;
    }
    @media #{$md-layout} {
      margin-right: 40px;
    }
    @media #{$xs-layout} {
      margin-right: 15px;
    }
    @media #{$sm-layout} {
      margin-right: 30px;
    }
  }
  @media #{$lg-layout} {
    &.mt-195 {
      margin-top: 125px;
    }
  }
  @media #{$md-layout} {
    &.mt-195 {
      margin-top: 0px;
      padding-top: 35px;
    }
  }
  @media #{$xs-layout} {
    &.mt-195 {
      margin-top: 0px;
      padding-top: 40px;
    }
  }
}